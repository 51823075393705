@import "variable.css";

body {
	/* font-size: calc(1.3em + 1vmin); */
	font-family: "Lato";
	font-weight: 400;
	/* modal is adding extra padding so overriding from here */
	padding-right: 0 !important;
}

.main {
	overflow: hidden;
}
.text-red {
	color: red;
}
.main-wrapper {
	margin-left: 270px;
	height: calc(100vh - 60px);
	overflow: hidden;
	overflow-y: auto;
}

.sub-title {
	font-style: normal;
	font-weight: 400;
	font-size: 34px;
	line-height: 114%;
}
.sub-paragraph {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.fullwidth {
	margin-left: 70px !important;
}

.transiton-02s {
	transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	-ms-transition: 0.2s ease-in;
	-moz-transition: 0.2s ease-in;
	-webkit-transition: 0.2s ease-in;
}

p {
	margin: 0;
	margin-bottom: 0;
}

a {
	text-decoration: none !important;
}

button {
	border: none;
}

.width-920 {
	max-width: 920px;
	width: 100%;
}

.width-900 {
	max-width: 900px;
	width: 100%;
}

.max-width-100 {
	max-width: 100% !important;
}

.minheight-100 {
	min-height: 100vh;
}

/* text properties */

.text-wrap {
	overflow-wrap: break-word !important;
}

/* cursor properties */

.cursor-pointer {
	cursor: pointer !important;
}

/* border-radius */

.rounded-5 {
	border-radius: 5px;
}

.rounded-10 {
	border-radius: 10px;
}

.rounded-15 {
	border-radius: 15px;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.border-grey {
	border: 1px solid #dadce0 !important;
}
.border-green {
	/* border: 1px solid #9ca61f !important; */
	border: 1px solid #f59d05 !important;
}

.b-none {
	border: none;
}

/* Fonts  */

.font-50 {
	font-size: 50px;
	line-height: 22px;
}

.font-40 {
	font-size: 40px;
	line-height: 35px;
}

.font-36 {
	font-size: 36px;
	line-height: 44px;
}

.font-26 {
	font-size: 26px;
	line-height: 36px;
}

.font-16 {
	font-size: 16px;
	line-height: 20px;
}

.font-20 {
	font-size: 20px;
	line-height: 30px;
}

.font-14 {
	font-size: 14px;
	line-height: 18px;
}

.font-13 {
	font-size: 13px;
	line-height: 26px;
}

.font-12 {
	font-size: 12px;
	line-height: 22px;
}

.font-11 {
	font-size: 11px;
	line-height: 16px;
}

/* font-colors  */
.color-theme {
	color: #212529;
}

.c-black {
	color: #0d0f12;
}
.c-gray {
	color: #999999;
}
.c-green {
	/* color: #9ca61f; */
	color: #f59d05;
}

a .c-green:hover {
	color: #f59d05 !important;
}
.c-orange {
	/* color: #f79e1b !important; */
	color: #052651 !important;
}
a:hover {
	color: #f59d05 !important;
}

.c-bg-green {
	/* background-color: #9ca61f; */
	background-color: #f59d05;
}

.color-light-grey {
	color: #868686;
	word-wrap: break-word;
}

.blue {
	color: #007de4;
}

.min-width {
	min-width: 130px;
}

.gray {
	color: gray;
}

.customer-plans {
	height: 38px !important;
	color: gray !important;
}

.color-dark-blue {
	color: #073d83;
}

.color-black1,
.active-color-black1:hover {
	color: #0d0f12;
}
.heigth-btn-banji {
	height: 38px;
}

.color-black2,
.active-color-black2:hover,
.active-color-black2:focus,
.active-color-black1:active,
.active-color-black1.active {
	color: #4c4f53;
}

.color-blue,
.active-color-blue:hover,
*:checked + .active-color-blue {
	color: #007de4;
}

*:checked + .active-border-blue,
.active-border-blue:hover {
	border-color: #007de4;
}

.color-white {
	color: #ffffff;
}

.color-light-white {
	color: #ffffffa3;
}

.color-green {
	color: #7ac142;
}

.color-grey {
	color: #dadce0;
}

.active-color-white:hover {
	color: #ffffff;
}

/* background */

.bg_white {
	background: #ffffff;
}

.modalHeader button {
	background-color: #fff !important;
}

.bg-gray1,
.nav-tabs .nav-link.bg-gray1,
.form-control:read-only.bg-gray1,
.form-select:disabled.bg-gray1 {
	background-color: #f8f8f8;
}

.nav-tabs .nav-link:not(.active) {
	border: 1px solid #ddd;
}

.bg-lightblue {
	background: #e8f0fe !important;
}

.bg-lightOrange {
	background-color: rgba(255, 139, 0, 0.1) !important;
}

.bg-blue {
	background: #007de4 !important;
}
.bg-disable-blue {
	background: #51aaf3 !important;
}
.bg-red {
	background: #f64e60 !important;
}

.bg-grey,
.edit-input:focus {
	background-color: #dadce0;
}
.bg-test,
.edit-input:focus {
	background-color: #007de4;
}

/* margin */

.mt-30 {
	margin-top: 30px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mr-30 {
	margin-right: 30px;
}

.mr-20 {
	margin-right: 20px;
}

.mr-15 {
	margin-right: 15px;
}

.mr-10 {
	margin-right: 10px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-20 {
	margin-left: 10px;
}

.mt-0 {
	margin-top: 0;
}

.my_10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.pb-70 {
	padding-bottom: 70px;
}

.pt-70 {
	padding-top: 70px;
}

.px-10 {
	padding: 0 10px;
}

.p-0 {
	padding: 0;
}

.py-20 {
	padding: 20px;
}

.py-15 {
	padding: 15px 0;
}

.py-10 {
	padding: 10px 0;
}

.p_5 {
	padding: 5px !important;
}

.p-10 {
	padding: 10px;
}

.p-20 {
	padding: 20px;
}
.p-30 {
	padding: 30px;
}
.p-40 {
	padding: 40px;
}

.pr-10 {
	padding-right: 10px;
}

.pl-25 {
	padding-left: 25px !important;
}

.pl-20 {
	padding-left: 20px !important;
}

.pl-0 {
	padding-left: 0;
}

.pr-0 {
	padding-right: 0;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-30 {
	padding-bottom: 30px;
}

/* font-weight  */
.fw-600 {
	font-weight: 600;
}

.font-700 {
	font-weight: 700;
}

.fw-900 {
	font-weight: 900;
}

/* disable  */

.disable {
	opacity: 0.3;
	pointer-events: none !important;
}

.icon-sm {
	font-size: 0.9em;
}

.w-60 {
	width: 60%;
}

.w-40 {
	width: 40%;
}

.w-250px {
	width: 250px;
}

.w-115px {
	width: 115px;
	min-width: 115px;
}

.h-50px {
	height: 50px;
}

.h-40 {
	height: 40px;
}

.dropdown-menu {
	font-size: inherit;
	z-index: 3;
}

.form-control:focus {
	box-shadow: none;
}

.custom-button {
	/* cursor: pointer;
  width: 30px;
  height: 30px; */

	padding: 5px;
	width: 80px;
	height: 60px;
	display: "flex";
	justify-content: "space-between";
	align-items: "center";
}

.btn-comman {
	min-width: 117px;
	height: 37px;
	border-radius: 4px;
	font-size: 15px;
	text-transform: capitalize;
}

.text-upper {
	text-transform: uppercase;
	color: #4c4f53;
}

.m-b-10 {
	margin-bottom: 10px;
}

.m-b-20 {
	margin-bottom: 20px;
}

.m-b-40 {
	margin-bottom: 40px;
}

.m-b-5 {
	margin-bottom: 5px;
}

.z-index {
	z-index: 2;
}

.info-name {
	min-width: 210px;
	text-transform: uppercase;
}

.info-image .photo {
	width: 60px;
	height: 60px;
	/* background-color: #073D83; */
	background-color: #c5c5c5;
	border-radius: 50%;
	margin-right: 20px;
	overflow: hidden;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 28px;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: gray;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #143d83;
}

input:focus + .slider {
	box-shadow: 0 0 1px #143d83;
}

.link-resend {
	color: #143d83;
	font-weight: 700;
}

input:checked + .slider:before {
	-webkit-transform: translateX(22px);
	-ms-transform: translateX(22px);
	transform: translateX(22px);
}

/* Rounded sliders */

.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

.edit-profile .info-field .info-image {
	overflow: hidden;
}

.roleModule {
	width: 33%;
}

hr {
	clear: both;
}

.input-hieght {
	min-height: 38px !important;
}

/* remove bottom border of last table row */
.table-section table {
	margin-bottom: 0;
}

.table-section table tr:nth-child(10) {
	/* border-bottom: none; */
}

/* remove border of first child */
.table > :not(:first-child) {
	border-top: 0px solid currentColor;
}

/* object fit cover */
.object-fit-cover {
	object-fit: cover !important;
}

.button-w-h-add {
	max-width: 160px;
	max-height: 37px;
}
.button-w-h-close {
	max-width: 47px;
	max-height: 37px;
}
/* hide arrow icons input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* custom invalid type */
.custom-invalid ~ .invalid-feedback {
	display: block;
}

.button-width-com {
	width: 100px !important;
	height: 38px !important;
}
.hover-btn:hover {
	background-color: #dadce0 !important ;
}
.button-width-com-big {
	width: 150px !important;
	height: 38px !important;
}

.hover-page {
	color: #868686;
}
.hover-page:hover {
	background-color: #d3d3d3 !important;
	color: #0d0f12;
}

.notification-box {
	position: absolute;
	right: 19px;
	top: 19px;
	background: #007de4;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #ffffff;
}

.notification-dot-icon {
	color: red;
}
/* poll-history page related css */
.poll-title {
	color: #005397;
	width: 100%;
	overflow: hidden;
	font-size: 34px;
	word-break: break-all;
	font-weight: 600;
	line-height: 52px;
}
.poll-sub-title {
	color: #005397;
	font-size: 24px;
	font-weight: 600;
}
.poll-dowload-button {
	color: #fff;
	padding: 15px 35px;
	font-size: 20px;
	border-radius: 18px;
	text-transform: capitalize;
	background-color: #00519a;
	font-weight: 500;
	line-height: 1.75;
	outline: 0 !important;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.poll-footer {
	background: rgb(0, 83, 151);
	border-radius: 10px;
	margin: 20px -20px -20px -20px;
	color: white;
	padding: 20px;
}
.poll-text {
	color: #9eaeba;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
}
.poll-normal {
	border: 1px solid #eef0f8;
	margin-top: 30px;
	border-radius: 12px;
	background-color: #ffffff;
	overflow: hidden;
}
.poll-span {
	max-width: 22px;
	background-color: rgb(0, 83, 151);
	border-radius: 12px;
}
.poll-span-button {
	min-width: 100px;
	height: 40px;
	border-radius: 40px;
	background: #00519a;
	color: #fff;
	font-weight: 500;
}
.poll-qr-dowload {
	width: 60px;
	background-color: #005397;
	height: 50px;
	border-radius: 12px;
	color: #fff;
	display: flex;
}
.poll-option {
	border: 1px solid #eef0f8;
	margin-top: 30px;
	border-radius: 12px;
	background: linear-gradient(0deg, #05539b 16%, #096ac4 100%);
	overflow: hidden;
	padding: 20px;
}

.voter-modal button {
	background: #fff;
	color: gray;
	font-size: 28px;
}

.voter-modal h6 {
	font-size: 1.25rem;
	font-family: Poppins;
	font-weight: 500;
	line-height: 1.6;
}

.created-date {
	min-width: 200px !important;
}

/* position relative */
.position-relative {
	position: relative;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* react transitions effects */

/* empty calss for the css navigation in editor, do not remove it */
.animatedListItem {
}

.animatedListItem-enter {
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
}
.animatedListItem-enter-active {
	opacity: 1;
	transform-origin: top;
	transform: scaleY(1);
	transition: all 200ms ease-out;
}
.animatedListItem-exit {
	opacity: 1;
	transform: scaleY(1);
	transform-origin: top;
}
.animatedListItem-exit-active {
	transform-origin: top;
	opacity: 0;
	transform: scaleY(0);
	transition: all 200ms ease-in;
}

/* box shadow design for list item input type */
.list-item-box-shadow {
	box-shadow: 3px 3px 8px 0px rgba(128, 128, 128, 0.24);
}

/* not allowed cursor */

button:disabled {
	cursor: not-allowed;
	pointer-events: all !important;
}

@media (max-width: 1680px) {
}

@media (max-width: 1399px) {
	/* .h-50px {
        height: 38px;
    } */
	.font-36 {
		font-size: 32px;
		line-height: 38px;
	}

	.font-30 {
		font-size: 26px;
		line-height: 32px;
	}
}

@media (max-width: 1199px) {
	.main-wrapper {
		margin-left: 0;
	}
}

@media (max-width: 991px) {
}

@media (max-width: 768px) {
	.main-wrapper {
		height: calc(100vh - 116.03px);
	}
}

@media only screen and (max-width: 767px) {
	.mr-20 {
		margin-right: 10px;
	}

	.mb-20 {
		margin-bottom: 10px;
	}

	.mb-30 {
		margin-bottom: 15px;
	}

	.font-20 {
		font-size: 16px;
	}

	.w-60 {
		width: 100%;
	}

	.w-40 {
		width: 100%;
	}

	.roleModule {
		width: 100%;
		margin-top: 20px;
	}
}
.analytics-button {
	color: #007de4;
	background-color: #fff;
	border-color: #007de4;
	border: 1px solid;
	font-weight: 600;
	padding: 2px 12px;
	font-size: 13px;
	border-radius: 4px;
	transition: 0.3s;
}

.analytics-button:hover {
	color: #fff;
	background-color: #007de4;
	border-color: #fff;
	border: 1px solid;
}

@media (max-width: 575px) {
	.filters select {
		width: calc(50% - 8px);
		margin-right: 16px !important;
	}

	.filters select.second {
		margin-right: 0 !important;
	}

	.filters .search-input {
		margin-right: 0 !important;
	}
}

@media (max-width: 374px) {
	.filters select {
		width: 100%;
		margin-right: 0 !important;
	}
}

@media (min-width: 700px) {
	.w-sm-250px {
		width: 250px !important;
	}

	.w-sm-auto {
		width: auto !important;
	}

	.mb-sm-0 {
		margin-bottom: 0 !important;
	}

	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
}

/* admin tables responsiveness */

.admin-table table th {
	min-width: 100px;
}
.admin-table table {
	table-layout: fixed;
	word-break: break-all;
	min-width: 1580px;
}
.banji-table table {
	table-layout: fixed;
	word-break: break-all;
	min-width: 1580px;
}
.bvote-table table {
	table-layout: fixed;
	word-break: break-all;
	min-width: 1580px;
}
.nft-label-section {
	height: 600px;
	overflow: auto;
	overflow-x: hidden;
}
/* 
.nft-label-section::-webkit-scrollbar-thumb {
  background-color: rgba(94, 63, 63, 0.5);
  border-radius: 5px;
  background-color: #f1f1f1;
} */
.nft-price {
	margin-top: 20px;
	border: 1px solid #fff;
	padding: 20px;
	border-radius: 10px;
	background-color: #fff;
}
.nft-price-input {
	width: 30%;
}
.nft-price-input label {
	font-size: 14px;
	font-weight: 700;
}
.nft-price-input input {
	font-size: 14px;
	margin-top: 5px;
}
@media (max-width: 767.98px) {
	.nft-price-input {
		width: 100%;
	}
}
.block-chain-header {
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 15px 15px;
	margin: 10px 0px;
	background: #e8f0fe;
	font-weight: 600;
}
.block-chain-object {
	padding: 15px;
	border: 1px solid gray;
	border-radius: 5px;
	margin: 3px;
	cursor: move !important;
}
.ml-30 {
	margin-left: 30px;
}
.blockchain-main-section {
	width: 650px;
}

.blockchainNote {
	color: gray;
	font-weight: 500;
}
@media (max-width: 767.98px) {
	.blockchain-main-section {
		width: 100%;
	}
	.blockchain-main-section img {
		width: 100%;
		height: 100%;
	}
	.ml-30 {
		margin-left: 20px;
	}

	.drag-blockchain-img {
		display: none;
	}
}
.category-icon-position {
	position: absolute;
	top: 20px;
	right: 20px;
}

.w-140 {
	width: 140px !important;
}

.w-750 {
	width: 75%;
}

@media (max-width: 767.98px) {
	.w-750 {
		width: 100%;
	}
}

.cutome-radio {
	width: 130px;
	background-color: #f0f0f0;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 5px;
	border: 1px solid rgb(197, 196, 196);
	font-size: 15px;
	font-weight: 600;
}
@media (max-width: 650px) {
	.cutome-radio {
		margin-left: 0;
		margin-top: 10px;
	}
}
.cutome-radio div {
	width: 80px;
}
.w-c-250 {
	width: 250px;
}

.red-c-color .css-1s2u09g-control {
	border: 1px solid red !important;
}

.error-border input {
	border-color: #dc3545 !important;
}

.app-custom {
	border: 1px solid #dadce0;
	background-color: #dadce0;
	border-radius: 5px;
	margin-right: 3px;
	padding: 2px 5px;
	font-size: 12px;
	font-weight: 700;
}

.ReactTagInput-error .react-tag-input {
	border: 1px solid red;
}

.ReactDatePickerError {
	border: 1px solid red;
}

.content_d {
	border: 1px solid #dadce0 !important;
	min-height: 38px;
	border-radius: 5px;
}
.content_red {
	border: 1px solid red !important;
	border-radius: 5px;
	min-height: 38px;
}
.selectTags .css-loboqh-control {
	border: 1px solid red !important;
}

.markeplace-form {
	border: 1px solid #ced4da;
	border-radius: 5px;
	margin-top: 15px;
	padding: 10px 10px 10px 10px;
}
.markeplace-form label {
	font-weight: 700;
}
.markeplace-form .error-text {
	color: #ee5060;
	padding: 10px;
	margin-top: 10px;
	border-radius: 5px;
	border: 1px solid #ee5060;
}
.markeplace-form-loadding {
	position: absolute;
	top: 50%;
	left: 45%;
	display: flex;
	justify-content: center;
}

.markeplace-form-main label {
	font-weight: 700;
}

.parent-app .css-1s2u09g-control {
	border: 1px solid #dc3545 !important;
}

.btn-primary {
	padding: 8px;
}

.custom-primary-outline {
	font-size: 18px;
	line-height: 20px;
	color: #f59d05;
	border: 1px solid #f59d05;
	outline: none;
	box-shadow: none;
	background-color: #fff;
	border-radius: 5px;
	padding: 0px 20px;
	height: 38px;
	max-height: 38px;
	font-size: 16px;
}

.custom-primary-outline:hover {
	/* border: 1px solid #9ca61f; */
	background-color: #f59d05;
	color: #fff;
	border: 1px solid #f59d05;
}
.custom-primary-outline:disabled {
	opacity: 0.7;
}

.custom-primary {
	font-size: 18px;
	line-height: 20px;
	color: #ffffff;
	border: 1px solid #f59d05;
	outline: none;
	box-shadow: none;
	border-radius: 5px;
	font-size: 16px;
	padding: 0px 20px;
	height: 38px;
	max-height: 38px;
	background: #f59d05 !important;
}
.custom-primary:disabled {
	opacity: 0.7;
}

.height-46 {
	height: 46px;
	max-height: 46px;
}
.custom-primary:hover {
	/* border: 1px solid #9ca61f; */
	border: 1px solid #f59d05;
}

.holders {
	padding: 10px 30px 0px 30px;
}

.holders .thumb-horizontal,
.thumb-vertical {
	display: block;
}

.row-count {
	padding: 2px 7px !important;
	font-size: 13px !important;
	color: #868686 !important;
}

.row-count:focus {
	box-shadow: none;
}

.pagination-section ul {
	margin: 0;
}

.pagination-number {
	width: 30px;
	height: 30px;
}

.disabled {
	cursor: no-drop;
	opacity: 0.6;
}

.pagination-number a {
	width: 100%;
	height: 100%;
}

.app-permission.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.pagination-number a:focus {
	box-shadow: none;
}

.pagination-number a:hover,
.pagination-number a:hover i {
	background-color: #e8f0fe;
	color: #0d0f12;
}

.pagination-number i {
	line-height: 15px;
	color: #868686;
	width: fit-content;
}

@media only screen and (max-width: 768px) {
	.holders {
		padding: 30px 15px;
	}
	.holder-form {
		margin-top: 20px;
	}
}

@media only screen and (max-width: 460px) {
	.pagination-section {
		margin-top: 10px;
	}
}

.table-section {
	/* border: 1px solid #DADCE0; */
	border-radius: 5px;
}

.table-section thead {
	background-color: #e8f0fe;
	position: sticky;
	top: 0;
	z-index: 1;
}

.table-section tbody tr {
	border-bottom: 1px solid #dadce0;
}

.table-section thead th,
tbody td {
	border: none;
}

.table-section thead th,
tbody th,
tbody td {
	padding: 17px !important;
}

.table-section .status-data {
	padding: 17px 17px 17px 35px !important;
}

.thumb-horizontal,
.thumb-vertical {
	background-color: #007de4;
	border-radius: 10px;
}

.table-section .holder-profile {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}

.table-section .holder-profile img {
	width: 100%;
	height: 100%;
}

.table-section .status {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: absolute;
	left: 17px;
	top: 22px;
}

.switch-margin-0 {
	margin-bottom: 0 !important;
}

#react-select-2-listbox {
	z-index: 7 !important;
}
#react-select-2-listbox .css-1h06qz8-control {
	height: 100%;
}
#react-select-2-listbox .css-lj6lawv-control {
	height: 100%;
}
#react-select-2-listbox .css-lj6lawv-control:hover {
	height: 100%;
}

.css-13cymwt-control {
	height: 42px !important;
}
.css-t3ipsp-control {
	height: 42px !important;
}
.react-select-error {
	border: none;
	/* border-color: red; */
	border: 1px solid #dc3545;
	border-radius: 5px;
}

.react-select-error .css-13cymwt-control {
	/* border:1px solid #dc3545; */
	border: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
	box-shadow: none;
}

.form-control:focus {
	border: 1px solid #ced4da;
}

.sidebar-toggle {
	border-left: 1px solid rgb(245, 157, 5);
}
.table-box-height {
	height: 38px;
}
.react-switch-handle {
	box-shadow: none !important;
}
