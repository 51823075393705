.login-section {
	/* background-image:  url(https://account-files-bucket.s3.ap-south-1.amazonaws.com/eTabby/login-bg.png); */
	background-size: cover;
	background-position: center;
}

.login-form {
	width: 500px;
	box-shadow: 2px 2px 5px #949494;
}

/* First Option */

/* .logo {
    margin-left: 50%;
    transform: translateX(-50%);
}
.logo-icon {
    width: 5%;
    height: 5%;
} */

/* Second option */
/* .logo {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5%;
}
.logo-icon {
    width: 25%;
    height: 25%;
}

.login-inner-sec {
    margin-top: 6%;
} */

.main-div-of-logo-login-sec {
	align-items: center;
}

.logo {
	margin-left: 50%;
	transform: translateX(-50%);
}

.logo-icon {
	width: 25%;
	height: 25%;
}

.eye-btn:hover {
	outline: 0 !important;
}

.pass-input {
	border: 1px solid red;
	border-radius: 5px;
}
